import React, { Component } from 'react';
import { Button, Form, Message, Dimmer, Loader } from 'semantic-ui-react';
import { Row, Column } from '../components/common/grid';
import { Container } from 'semantic-ui-react';
import { USER_API_URL } from '../constants/constants';
import Axios from 'axios';

class ResetPassword extends Component {
  state = {
    email: '',
    errors: false,
    errorMessage: null
  };

  validateForm = () => {
    if (this.state.email.trim().length > 0) {
      this.setState({ errors: false });
      return true;
    } else {
      this.setState({ errors: true });
      return false;
    }
  };

  handleSubmit = () => {
    this.setState({ errorMessage: null, errors: false });
    if (this.validateForm()) {
      this.handleResetPassword(this.state.email);
    } else {
      this.setState({ errors: true });
    }
  };

  handleResetPassword = email => {
    this.setState({ loading: true });
    Axios.post(`${USER_API_URL}/reset-password-email`, {
      email: email
    }).then(response => {
      if (response.data.success) {
        this.setState({ loading: false });
        alert('Check your email for instructions');
      } else {
        alert('Error: ' + response.data.message);
        this.setState({ loading: false });
      }
    });
  };

  render() {
    return (
      <Container>
        <Row>
          <Column>
            <Form>
              <Column>
                <Form.Input
                  icon='envelope'
                  type='text'
                  placeholder='chewy@kashyyyk.com'
                  label="What's your email address?"
                  onChange={() =>
                    this.setState({
                      email: event.target.value
                    })
                  }
                />
                <Button onClick={this.handleSubmit}>Reset Password</Button>
                {this.state.errors ? <Message>Enter an email address</Message> : null}
                {this.state.errorMessage ? <Message>{this.state.errorMessage}</Message> : null}
              </Column>
            </Form>
          </Column>
          {/* 
          // @ts-ignore */}
          <Column />
        </Row>
        <Dimmer inverted active={this.props.loading}>
          <Loader content='Sending email' />
        </Dimmer>
      </Container>
    );
  }
}

export default ResetPassword;
